<template>
  <div :class="modalClasses" role="dialog">
    <div class="flex flex-col gap-s24 capitalize">
      <h3 class="headline-small">
        {{ $t(`wallet.modal.add_methods.title`, { type }) }}
      </h3>
      <div class="flex flex-col gap-s16">
        <h5 class="subheadline-medium">
          {{ $t('wallet.modal.add_methods.current_balance') }}: <span class="text-text-positive">{{balance}}</span>
        </h5>
        <h5 v-if="isDeposit" class="flex gap-s4 subheadline-medium">
          {{ $t(`wallet.modal.add_methods.limit`, { type }) }}:
          <span class="text-text-positive">{{limit}}</span>
          <button class="align-middle text-text-positive info-button" @click="showLimitModal">
            <IconInfo
              class="w-s16 h-s16 fill-current default-transition hover:opacity-60"
            />
          </button>
        </h5>
      </div>
    </div>

    <div class="flex flex-col mt-s32 mb-s40 gap-s16">
      <slot />
    </div>

    <div class="flex flex-col items-end gap-s16 -m-s16 p-s16">
      <ButtonV2
        v-if="showIncreaseLimitButton"
        class="capitalize"
        testId="btn-increaseLimit"
        size="large"
        :label="$t('wallet.modal.add_methods.increase_limit', { type })"
        @onClick="showModal('KycStatus')"
        wide
      />
      <ButtonV2
        @onClick="hideModal"
        :label="$t('cancel')"
        testId="btn-cancel"
        size="large"
        version="secondary"
        wide
      />
    </div>
  </div>
</template>

<script>
import { IconInfo } from '@/assets/icons';
import ButtonV2 from '@/stories/misc/ButtonV2';
import { validateType } from '../types';

export default {
  name: 'BaseAddMethodLayout',
  components: {
    ButtonV2,
    IconInfo,
  },
  props: {
    balance: {
      type: String,
      required: true
    },
    limit: {
      type: String,
      required: true
    },
    showIncreaseLimitButton: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      required: true,
      validator: validateType
    },
  },
  computed: {
    modalClasses(){
      return {
        'modal-content': true,
        'modal-content--large': !this.isMobileDevice,
        'modal-content--small': this.isMobileDevice,
      };
    },
    isDeposit() {
      return this.type === 'deposit';
    },
  },
  methods: {
    showLimitModal(){
      return this.showModal('WalletTransferLimit', { type: this.type, isClosable: false, noPadding: true });
    }
  }
};
</script>

<style scoped>
.info-button {
  outline: 0;
}

.modal-content {
  @apply py-s20;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
}

.modal-content--large {
  min-width: 450px;
}

.modal-content--small {
  width: 100%;
}
</style>

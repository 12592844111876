<template>
  <component :is="componentType" v-bind="componentProps" />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import WalletAddDepositMethod from '@/modules/walletv2/WalletModals/AddDepositMethod/index.vue';
import WalletAddWithdrawMethod from '@/modules/walletv2/WalletModals/AddWithdrawMethod/index.vue';
import { addSDK, createPlaidWidget, removeSDK } from '@/utils/sdk';


export default {
  name: 'AddTransferMethod',
  components: {
    WalletAddDepositMethod,
    WalletAddWithdrawMethod
  },
  data() {
    return {
      plaidPlugin: null,
      plaidHandler: null,
      plaidScriptId: 'plaid-script-dom',
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    }
  },
  mounted() {
    this.getTokenForPlaid();
  },
  destroyed() {
    this.unmountPlaidWidget();
  },
  watch: {
    currentPlaidToken(value) {
      if (value) {
        this.mountPlaidWidget();
      }
    },
  },
  computed: {
    ...mapGetters('user', [
      'isKycComplete',
    ]),
    ...mapGetters('wallet', [
      'getBuyingPower',
      'getLimitByType',
      'getDepositMethods',
      'getWithdrawalMethods',
    ]),
    ...mapState('wallet', ['currentPlaidToken']),
    componentType(){
      switch (this.type) {
        case 'deposit':
          return 'WalletAddDepositMethod';

        case 'withdraw':
          return 'WalletAddWithdrawMethod';
        
        default:
          return 'WalletAddDepositMethod';
      }
    },
    limitType(){
      return this.getLimitByType(this.type);
    },
    methodsByType(){
      if (this.type === 'withdraw') {
       return this.getWithdrawalMethods; 
      }
     return this.getDepositMethods; 
    },
    componentProps(){
      return {
        balance: this.getBuyingPower,
        kycComplete: this.isKycComplete,
        limit: this.limitType,
        methods: this.methodsByType,
        openPlaidWidget: this.openPlaidWidget,
      };
    }
  },
  methods: {
    ...mapActions('wallet', ['getTokenForPlaid', 'postCreateAchDepositMethod']),
    ...mapActions('ui', ['setIsOverlayLoading']),

    async onPlaidSuccess(token, data) {
      try {
        this.setIsOverlayLoading(true);

        await this.postCreateAchDepositMethod({
          plaid_public_token: token,
          plaid_account_id: data.account_id,
        });

        this.showModal('PaymentInterface', { isDeposit: this.type === 'deposit' });
      } catch (err) {
        this.showError(err);
      } finally {
        this.setIsOverlayLoading(false);
      }
    },

    async addPlaidToLocalHandler() {
      this.plaidHandler = await createPlaidWidget(this.currentPlaidToken, this.plaidPlugin, {
        onSuccess: this.onPlaidSuccess,
      });
    },

    async mountPlaidWidget() {
      try {
        await addSDK(this.plaidScriptId, process.env.VUE_APP_PLAID_ACH_SCRIPT);
        this.plaidPlugin = window.Plaid;
        this.addPlaidToLocalHandler();
      } catch(e) {
        this.showError(e);
      }
    },

    unmountPlaidWidget() {
      if (this.plaidHandler) {
        this.plaidHandler.destroy();
      }
      removeSDK(`#${this.plaidScriptId}`);
      removeSDK('[href^="https://cdn.plaid.com/"]');
      removeSDK('[id^="plaid-link-iframe"]');
      if (window.Plaid) {
        window.Plaid = null;
      }
    },

    openPlaidWidget() {
      if (!this.plaidHandler) {
        setTimeout(() => {
          return this.openPlaidWidget();
        }, 250); // keep re-trying until widget is ready
        return;
      }

      this.plaidHandler.open();
    },
  }
};
</script>

<template>
  <BaseAddMethodLayout v-bind="layoutProps">
    <template v-for="method in methods">
      <PaymentTypeButton
        :data-cy="`payment-type-${method.type}`" 
        :data-testid="`payment-type-${method.type}`" 
        v-if="method.enabled"
        :key="method.id"
        :title="$t('wallet.methods.' + method.title)"
        :icon="method.icon"
        :fee="getFee(method.fee)"
        :funds="$t('wallet.' + method.funds)"
        @click.native="depositMethods(method.type)"
        v-bind="method"
      />
    </template>
  </BaseAddMethodLayout>
</template>

<script>
import CirclePaymentType from '@/enums/CirclePaymentType.js';
import { BaseAddMethodLayout } from '@/modules/walletv2/WalletModals';
import { PaymentTypeButton } from '@/components/misc';
import { validateMethodOfPayment } from '../types';

export default {
  name: 'AddDepositMethod',
  components: {
    BaseAddMethodLayout,
    PaymentTypeButton,
  },
  props: {
    balance: {
      type: String,
      required: true,
    },
    kycComplete: {
      type: Boolean,
      required: true,
    },
    limit: {
      type: String,
      required: true,
    },
    methods: {
      type: Array,
      required: true,
      validator: validateMethodOfPayment,
    },
    openPlaidWidget: {
      type: Function,
      required: true,
    },
    type: {
      type: String,
      default: 'deposit',
    },
  },
  computed: {
    showIncreaseLimitButton() {
      return this.userPermissions && !this.userPermissions.withdraw;
    },

    userPermissions(){
      return this.$store.getters['user/getUserPermissions'];
    },

    layoutProps() {
      const limit = this.userPermissions && this.userPermissions.withdraw ? 10000:500;
      return {
        balance: this.balance,
        limit: `${this.numberFormat(limit, 2, false, true)}`,
        showIncreaseLimitButton: this.showIncreaseLimitButton,
        type: this.type,
      };
    },
  },
  methods: {
    getFee(fee) {
      if (fee <= 0) {
        return this.$t('wallet.methods.no_fee');
      } else {
        return `${this.numberFormat(fee, 2, false, true)} ${this.$t('wallet.methods.fee')}`;
      }
    },
    async depositMethods(type) {
      switch (type) {
        case CirclePaymentType.ACH:
          await this.$store.dispatch('events/track', {
            event: 'ADD_ACH_VIEWED',
            variables: {
              transfer_method: 'deposit',
            },
          });
          return this.openPlaidWidget();
        case CirclePaymentType.CARD:
          await this.$store.dispatch('events/track', {
            event: 'ADD_CREDIT_CARD_VIEWED',
          });
          return this.showModal('AddCreditCardMethod');
        case CirclePaymentType.BLOCKCHAIN:
          await this.$store.dispatch('events/track', {
            event: 'ADD_USDC_VIEWED',
          });
          return this.showModal('PaymentInterface', {
            isDeposit: true,
            selectedMethodId: CirclePaymentType.BLOCKCHAIN,
          });
        case CirclePaymentType.WIRE:
          await this.$store.dispatch('events/track', {
            event: 'ADD_WIRE_VIEWED',
          });
          return this.showModal('WalletAddWireTransferDetails');
        default:
          throw Error('This transfer method don\'t exist');
      }
    },
  },
};
</script>
